<template>
  <div>
    <cp-head-table
      :title="$t('issuerAgreements.title')"
      :button-title="$t('issuerAgreements.button.agreements.add')"
      title-icon="ios-build"
      button-icon="ios-add-circle-outline"
      @onCreateElem="toCreateModal"
    />
    <cp-table
      ref="cpTable"
      get-data-action="issuerAgreements/getIssuerAgreements"
      :url-params="urlParams"
      :fields="fields"
    >
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.edit')"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.edit') }}
        </b-btn>
        <b-btn
          v-b-tooltip.hover="true"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.delete')"
          @click="openDeleteModal(rowData.item)"
        >
          <i
            class="ion ion-ios-trash"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.delete') }}
        </b-btn>
      </template>
    </cp-table>
    <cp-delete-modal
      ref="cpDeleteAlert"
      :item-info="deleteItem"
      @delete-item="removeItem"
    />
    <cp-agreement-modal
      ref="CpIssuerAgreement"
      :title-data="titleData"
      :modal-data="agreementData"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import CpTable from '~/components/shared/cp-table';
import CpAgreementModal from './issuer-agreement-modal';
import CpDeleteModal from '~/components/common/table/deleteModal';
import CpHeadTable from '~/components/common/head';

export default {
  name: 'Agreements',
  metaInfo: {
    title: 'Agreements',
  },

  components: {
    CpTable,
    CpHeadTable,
    CpAgreementModal,
    CpDeleteModal,
  },

  data() {
    return {
      fields: [
        { key: 'name', i18nKey: 'issuerAgreements.label.table.name', tdClass: 'align-middle' },
        { key: 'title', i18nKey: 'issuerAgreements.label.table.title', tdClass: 'align-middle' },
        { key: 'actions', label: ' ', tdClass: 'text-nowrap align-middle text-center' },
      ],
      agreementData: {},
      deleteItem: null,
      titleData: {},
      urlParams: { issuerId: this.$route.params.idIssuer },
    };
  },

  methods: {
    ...mapActions('issuerAgreements', [
      'create',
      'getByName',
      'update',
      'delete',
    ]),

    hideModal() {
      this.$refs.CpIssuerAgreement.$refs.issuerAgreement.hide();
    },

    showModal() {
      this.$refs.CpIssuerAgreement.$refs.issuerAgreement.show();
    },

    toCreateModal() {
      this.titleData = {
        type: 'Add',
        label: this.$t('common.button.add'),
        icon: 'ios-add-circle-outline',
      };

      this.agreementData = { type: 'yes' };
      this.showModal();
    },

    toEditModal({ name }) {
      this.titleData = {
        type: 'Edit',
        label: this.$t('common.button.edit'),
        icon: 'md-create',
      };
      this.getByName({
        issuerId: this.$route.params.idIssuer,
        agreementName: name,
      }).then((data) => {
        this.agreementData = Object.assign({}, data);
        this.showModal();
      });
    },

    openDeleteModal(item) {
      this.deleteItem = item;
      this.$refs.cpDeleteAlert.$refs.deleteAlert.open();
    },

    removeItem() {
      const { name } = this.deleteItem;
      this.delete({
        issuerId: this.$route.params.idIssuer,
        name,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        setTimeout(() => { this.deleteItem = null; }, 1000);
      }).finally(() => {
        this.$refs.cpDeleteAlert.$refs.deleteAlert.close();
      });
    },

    saveChanges({ action, agreement }) {
      agreement.text = agreement.text || '';

      this[action]({
        issuerId: this.$route.params.idIssuer,
        agreement,
      }).then(() => {
        this.hideModal();
        this.$refs.cpTable.updateTableData();
      });
    },
  },
};
</script>
