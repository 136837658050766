<template>
  <cp-general-modal
    ref="issuerAgreement"
    :title="getTitleName"
    :title-icon="`ion ion-${titleData.icon}`"
    :form-group-value="modalData"
    @onOk="handleSubmit"
  >
    <cp-input
      v-model="model.name"
      name="name"
      :label="$t('issuerAgreements.agreementModal.input.name')"
      :disabled="titleData.type === 'Edit'"
    />
    <cp-input
      v-model="model.title"
      name="title"
      :label="$t('issuerAgreements.agreementModal.input.title')"
    />
    <cp-input
      v-model="model.subtitle"
      name="subtitle"
      :label="$t('issuerAgreements.agreementModal.input.subtitle')"
    />
    <cp-select
      v-model="model.type"
      name="type"
      :label="$t('issuerAgreements.agreementModal.input.type')"
      :options="types"
    />
    <template v-if="model.type === 'yes_no'">
      <cp-input
        v-model="model.yesText"
        name="yesText"
        :label="$t('issuerAgreements.agreementModal.input.yesText')"
      />
      <cp-input
        v-model="model.noText"
        name="noText"
        :label="$t('issuerAgreements.agreementModal.input.noText')"
      />
    </template>
    <cp-input
      v-model="model.documentUrl"
      name="documentUrl"
      :label="$t('issuerAgreements.agreementModal.input.documentUrl')"
    />
    <cp-input
      v-model="model.checkOptions"
      name="checkOptions"
      :label="$t('issuerAgreements.agreementModal.input.checkOptions')"
    />
    <cp-textarea
      v-model="model.text"
      name="text"
      :label="$t('issuerAgreements.agreementModal.input.text')"
      :rows="5"
    />
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';

export default {
  name: 'IssuerAgreementModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
    CpTextarea,
  },

  props: {
    titleData: {
      type: Object,
      default: () => {},
    },
    modalData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      model: {},
      types: [
        { value: 'yes', i18nKey: 'issuerAgreements.agreementModal.status.yes' },
        { value: 'yes_no', i18nKey: 'issuerAgreements.agreementModal.status.yesNo' },
      ],
    };
  },

  computed: {
    getTitleName() {
      return this.$t('issuerAgreements.agreementModal.title', [this.titleData.label]);
    },
  },

  watch: {
    modalData: {
      handler(val) {
        this.model = Object.assign({}, val);
      },
    },
  },

  methods: {
    handleSubmit() {
      const action = this.titleData.type === 'Edit' ? 'update' : 'create';
      this.model.yesText = this.model.yesText || '';
      this.model.noText = this.model.noText || '';
      this.$emit('onSubmit', { action, agreement: this.model });
    },
  },
};
</script>
